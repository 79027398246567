
import React, { useState, useEffect } from "react";
import Hero from "../components/Mobileapp/hero"
import Servicecard from "../components/Mobileapp/Servicecard"
import Process from '../components/Mobileapp/Process';
import Othersevice from '../components/Mobileapp/Othersevice';
import Faq from '../components/Mobileapp/Faq';
import PortfolioCom from '../components/Home/PortfolioCom';
import IndustriesCom from '../components/Home/IndustriesCom';
import WhyVasundhara from '../components/Home/WhyVasundhara';
import Testimonials from '../components/Home/Testimonials';
import Blog from '../components/Home/Blog';
import UpworkCard from '../components/Mobileapp/Upworkcard';
import allApiData from "../api.js";
import Layout from "../components/Layout";
import allData from "../allData";
import Seo from "../components/Seo";
import SeoheadData from '../siteScriptData';


const ServicecardData = allData.appServiceData;
const appFaqData = allData.appFaqData;
const whyWebData = allData.whywebdata;
const OtherGameData = allData.otherServiceData('app');
const industriesData = allData.industriesData;


let imageData = [
    {
        head: 'Understanding Requirements',
        para: `This stage helps us understand client's perspective and end goals.`,
        imageurl: '../assets/img/mobileapp/process/understanding-requirements.svg',
    },
    {
        head: 'Creating Strategies',
        para: 'Various strategies are developed to deliver the project on time.',
        imageurl: '../assets/img/mobileapp/process/creating-strategies.svg'
    },
    {
        head: 'Using Right Technology',
        para: 'Further, we select technology to use for app development which depends on the type of application.',
        imageurl: '../assets/img/mobileapp/process/using-right-technology.svg'
    },
    {
        head: 'Planning',
        para: 'After gathering all the data, the project is divided into steps and a timeline is created.',
        imageurl: '../assets/img/mobileapp/process/planning.svg'
    },
    {
        head: 'Working On Design',
        para: 'Our UI & UX designers work on the look and feel of the application.',
        imageurl: '../assets/img/mobileapp/process/working-on-design.svg'
    },
    {
        head: 'Developing The App',
        para: 'A team of developers works simultaneously on the coding and app development.',
        imageurl: '../assets/img/mobileapp/process/developing-the-app.svg'
    },
    {
        head: 'Testing Phase',
        para: 'Once the app is developed, it goes through a thorough testing process for bugs and errors.',
        imageurl: '../assets/img/mobileapp/process/testing-phase.svg'
    },
    {
        head: 'Launching Project',
        para: 'After successful testing, the project is delivered to the client.',
        imageurl: '../assets/img/mobileapp/process/launching-project.svg'
    },
];

function Mobileapp() {

    const [allData, setallData] = useState({});
    const [portFolio, setPortFolio] = useState([])
    useEffect(() => {
        allApiData.aosinit();
        allApiData.getHomeApi().then((response) => {
            setallData(response)
        })
        allApiData.allPortfolios('', 'App Development', 'app_type').then((response) => {
            setPortFolio(response.data);
        })
    }, [])
    const mobiletabDAta = ['Frontend', 'Backend', 'Testing'];
    // const mobiletabDAta = ['Design', 'Frontend', 'Backend', 'Testing'];
    allApiData.removeanimation();

    return (
        <Layout>
            <Seo SeoData={SeoheadData.mobileAppPageSeoData}></Seo>

            <Hero title="Mobile App Development Company" def="We build dynamic, user-friendly, and engaging mobile applications." cta="Get a Quote">
                <div className="hero-img hide-animation-768">
                    <img src="../assets/img/mobileapp/hero_img/mobile-app-development-services.svg" className="img-fluid wh-auto" alt="mobile-app-development-services" width="743" height="530" />
                    <img src="../assets/img/mobileapp/hero_img/1.svg" className="app-img-one" alt="mobile-app-development-services-one" />
                    <img src="../assets/img/mobileapp/hero_img/2.svg" className=" img-fluid app-img-two" alt="mobile-app-development-services-two" />
                    <img src="../assets/img/mobileapp/hero_img/3.svg" className=" img-fluid app-img-three" alt="mobile-app-development-services-three" />

                </div>
                <div className="show-main-img-768">
                    <img src="../assets/img/mobileapp/hero_img/mobile-app-development-services-mobile.svg" className="wh-auto img-fluid" alt="mobile-app-development-services-mobile"
                    width="680" height="486" />
                </div>
            </Hero>
            <UpworkCard />
            <div className='bg-gray app-service-card pb-lg-5'>
                <Servicecard h1="App Development Solutions"
                    serviceData={ServicecardData}
                />
            </div>

            <div className="app-dept">
                <Process h1="Our Application Development Process" proccesdata={imageData} />
            </div>
            <div className="bg-blue-light tech-img py-lg-5">
                <PortfolioCom heading="Technologies We Work With" isTabOn={true} tabData={mobiletabDAta} activeTab="Frontend" isImageComp={false} isLimitPortF={8} />
            </div>
            <IndustriesCom h1="Developing Software Solutions for Diverse Verticals" industriesData={industriesData} buttonOn={true} />

            <div className="spacer-head">
                <PortfolioCom heading="Portfolio of Mobile App Development Services" isTabOn={false} isImageComp={true} portfolios={portFolio} isLimitPortF={3} />
            </div>

            <WhyVasundhara datas={whyWebData} />
            <Testimonials testimonialsdata={allData.testimonials} />

            <Othersevice head='Check Out Other Services We Offer ' otherData={OtherGameData} />

            <div className="blog-section nft-blog">
                <Blog heding="Latest Blogs and Insights from Vasundhara" blogdata={allData.blogs} />
            </div>
            <Faq title="FAQ's for App Development Services" faqData={appFaqData} buttnText='Check out more FAQs here' />
        </Layout>
    )
}

export default React.memo(Mobileapp)
