import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function CaseDetail(props) {
  const {
    description,
    casestudy,
    briefdescription,
  } = props;

  let createMarkup = () => ({ __html: briefdescription });
  let BriefDesc = () => (<div dangerouslySetInnerHTML={createMarkup()} />);

  return (
    <div>
      <Container className='pb-5 pt-lg-5 mt-lg-5 mt-sm-4 pt-4'>
        <section className='case-study-main'>
          <div className='mb-md-5 mb-sm-4 mb-4 pb-lg-5 pb-4'>
            {
              description ?
                <p className="accubow-p">{description}</p>
                : ''
            }
            {
              casestudy ?
                <>
                  <h5 className='case'>Case Study</h5>
                  <p className="accubow-p">{casestudy}</p>
                </>
                : ''
            }
          </div>

          <BriefDesc />

        </section>
      </Container>
    </div>
  )
}

export default CaseDetail