import React, { useEffect } from "react"
import { Redirect } from "react-router-dom";
import { Router } from "@reach/router";
import Blogpage from '../components/blog-details';
import GameDevelopement from '../pages/game-development-company';
import AppDevelopement from '../pages/app-development-company';
import Nft from '../pages/nft-development-company';
import Webdevelopment from '../pages/web-development-company';
import IndexPage from "./index";
import Casestudy from "./case-study-two";
import NotFound from "../components/NotFound";
// import Bloge from "./blogs";
import { navigate } from "gatsby";

const App = () => {

    const services = typeof window !== "undefined" ? window.location.pathname.split("/") : null;
    const blogPage = services && services.includes("blog");
    // useEffect(() => {
    // let search = typeof window !== 'undefined' ? window.location.search : null;
    // let pathName = typeof window !== 'undefined' ? window.location.pathname : null;
    // console.log(search);
    // if (search.length > 0 && pathName == '/') {
    //     navigate('/')
    // }
    // }, [])

    useEffect(() => {
        if (blogPage) { navigate(`/blogs/${services[2]}`) }
        if (blogPage && services[2] == 'revealing-vasundhara-new-identity') { navigate('/blogs/revealing-vasundharas-new-identity') }
        if (services[1] == 'mobile-game-development-ideas') { navigate('/blogs/mobile-game-development-ideas') }
        // if (blogPage1 && services[2] == '') { navigate('/blogs') }
    }, [blogPage, services])

    return (
        <div className="app">
            <Router>
                <Blogpage path="blogs/:blogtag" />
                <GameDevelopement path="mobile-game-development-company" />
                <AppDevelopement path="mobile-app-development-company" />
                {/* <Nft path="nft-development" /> */}
                <Webdevelopment path="web-development" />
                <Casestudy path="case-study/:casetag" />
                {/* <NotFound path="*" /> */}
                {/* <Redirect from="*" to="/" /> */}
                <IndexPage path="*" />
            </Router>
        </div>
    )
}

export default App