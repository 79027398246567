import React, { useEffect, useState } from 'react';
// import { Spinner } from 'react-bootstrap';
import Layout from './Layout'
import Subblog from './sub-blog/Sub-blog-category';
import allApiData from '../api';
import Seo from './Seo';

export default function BlogDeatils(props) {

    useEffect(() => {
        window.location.reload();
    }, [])

    let setblogtag = props.blogtag;

    // const [loading, setLoading] = useState(false);
    const [allData, setallData] = useState([]);
    const [blogDeetails, setBlogDetails] = useState([]);
    const [likebuttonclick, setlikeButonClick] = useState(1);
    const [footerData, setFooterData] = useState([]);

    let likebutonclickfunc = () => {
        setlikeButonClick(likebuttonclick + 1);
    }

    let fetchdata = async () => {
        allApiData.getDetailsOfBlog(setblogtag).then((blog) => {
            setBlogDetails(blog.data[0])
        })
    }
    useEffect(() => {
        fetchdata()
    }, [likebuttonclick])

    useEffect(() => {
        fetchdata();
    }, [props.blogtag])

    useEffect(() => {
        allApiData.aosinit();
        fetchdata()
        allApiData.allblogData().then((response) => {
            setallData(response.data)
        });
        allApiData.getHomeApi().then((data) => {
            setFooterData(data.footer_settings)
        });
    }, []);

    return (
        <Layout>
            <Seo SeoData={blogDeetails.seo ? blogDeetails.seo : ''}></Seo>

            <div className='blog-page sub-bloge-two'>
                {/* {
                    loading
                        ?
                        <>
                            <div className="d-flex justify-content-center py-5" style={{ marginBottom: "13rem" }}>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </>
                        :
                    <> */}
                {
                    blogDeetails && <>
                        <Subblog blogDetail={blogDeetails} likebutonclickfunc={likebutonclickfunc} footerData={footerData} allData={allData} />
                    </>
                }
                {/* </>
                } */}

            </div>

        </Layout>
    )
}
