import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import allAPiData from '../../api';
import Emailvalidator from 'email-validator';


function Subblogform(props) {
    const [commentFormData, setCommentFormData] = useState({
        Name: "",
        Email: "",
        Comment: "",
    });
    const { blogId } = props;

    const notify = (error, msg) => {
        if (error) {
            toast.error(msg);
            return false;
        }
        toast.success(msg);
    };

    let onChangeInputValue = async (e) => {
        setCommentFormData({
            ...commentFormData, [e.target.name]: e.target.value
        });
    }

    let onFormSubmit = async (e) => {
        e.preventDefault();
        var allfieldDone = [];

        if (Emailvalidator.validate(commentFormData.Email) !== false && commentFormData.Email.length !== 0) {
            allfieldDone.push(true);

        }

        // if (commentFormData.Email.length == 0) {
        //     allfieldDone.push(false);
        //     notify(true, "Please enter email");
        //     return;
        // }

        Object.keys(commentFormData).map((fieldName, i) => {
            if (commentFormData[fieldName].length > 0) {
                allfieldDone.push(true);
            } else {
                allfieldDone.push(false);
                if (fieldName === 'Name') {
                    notify(true, `Name is required.`);
                } else if (fieldName === 'Email') {
                    notify(true, `Email is required.`);
                } else if (fieldName === 'Comment') {
                    notify(true, `Comment is required.`);
                }
                return allfieldDone;
            }
        });
        if (commentFormData.Name) {
            if (commentFormData.Name.length < 3) {
                allfieldDone.push(false);
                notify(true, `The name must be at least 3 characters.`);
            }
        }
        if (commentFormData.Email) {
            if (Emailvalidator.validate(commentFormData.Email) === false) {
                notify(true, `Email is not valid`);
                allfieldDone.push(false);
            }
        }
        if (commentFormData.Comment) {
            if (commentFormData.Comment.length < 4) {
                allfieldDone.push(false);
                notify(true, `The comment must be at least 4 characters.`);
            }
        }


        const aalFieldDone = allfieldDone.every((val, i, arr) => val === true)


        let sendFormData = {
            name: commentFormData.Name,
            email: commentFormData.Email,
            comment: commentFormData.Comment,
            blog_id: blogId
        };

        if (aalFieldDone) {
            let data = await allAPiData.addCommentOnBlogs(sendFormData);

            setCommentFormData({
                Name: "",
                Email: "",
                Comment: "",
            });
            notify(false, `Comment Added Successfully`);
            // navigate('/thankyou', { replace: true })
        }
    }

    return (
        <div>
            <Container className='container1'>
                <div className="mainsub-form">
                    <h5>Leave a Comment</h5>
                    <form action="">
                        <Row>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="subname">Full Name <span>*</span></label>
                                    <input type="text" name="Name" className="form-control" placeholder="Enter your full name" value={commentFormData.Name} onChange={(e) => onChangeInputValue(e)} />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="subemail">Email <span>*</span></label>
                                    <input type="email" name="Email" className="form-control" placeholder="Enter your email" value={commentFormData.Email} onChange={(e) => onChangeInputValue(e)} />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="subcomment">Comment <span>*</span></label>
                                    <textarea name="Comment" cols="30" rows="10" className="form-control" placeholder='Comment here...' value={commentFormData.Comment} onChange={(e) => onChangeInputValue(e)}></textarea>
                                </div>
                            </div>

                            <div className="col-lg-12 text-sm-end text-center">
                                <button className="mt-4" onClick={(e) => onFormSubmit(e)}>Post Comment</button>
                            </div>
                        </Row>
                    </form>
                </div>

            </Container>
        </div>
    )
}

export default Subblogform