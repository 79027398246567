import React, { useState, useEffect } from "react";
import Caseslider from '../components/Casestudy/CaseSlider';
import CasestudyHero from '../components/Casestudy/CasestudyHero';
import TeamMemberCard from '../components/Casestudy/TeamMemberCard';
import Layout from "../components/Layout"
import Techstack from '../components/Casestudy/Techstack';
import Accubowaddition from '../components/Casestudy/Accubowaddition';
import Casedownloadapp from '../components/Casestudy/Casedownloadapp';
import CaseDetail from '../components/Casestudy/CaseDetail';
import Blog from '../components/Home/Blog';
import allApiData from "../api.js";
import Seo from "../components/Seo";
import SeoheadData from "../siteScriptData";
import axios from "axios";
import CaseVideo from "../components/Casestudy/CaseVideo";


export default function Casestudytwo(props) {
    useEffect(() => {
        window.location.reload();
    }, [])

    let caseTag = props.casetag;
    const [allData, setallData] = useState([]);

    const [subCaseStudy, setSubCaseStudy] = useState([]);
    const [bannerImg, setBannerImg] = useState("");
    const [footerImg, setFooterImg] = useState("");

    let subCaseDataFunc = (caseTag) => {
        allApiData.caseStudyDetails(caseTag).then((_case) => {
            setSubCaseStudy(_case.data[0]);
            let bannerImg = _case.data[0].medias.find((_ele) => { return _ele.type == 'banner_image' });
            let footerImg = _case.data[0].medias.find((_ele) => { return _ele.type == 'footer_image' });
            setBannerImg(bannerImg && bannerImg.image_path);
            setFooterImg(footerImg && footerImg.image_path)
        })
    }
    useEffect(() => {
        subCaseDataFunc(caseTag);
        allCaseStudy();
    }, [props.casetag]);

    allApiData.removeanimation();

    let allCaseStudy = async () => {
        let bodyForm = new FormData();
        bodyForm.append('type', 'All');
        let caseData = await axios.post(`${allApiData.url}/caseStudies`, bodyForm);
        setallData(caseData.data.data);
    }

    useEffect(() => {
        allApiData.aosinit();
        // allApiData.getHomeApi().then((response) => {
        //     console.log(response,'response');
        //     setallData(response)
        // })
    }, []);

    let filterCaseData = allData.filter((_ele) => {
        return _ele.id !== subCaseStudy.id;
    });

    return (
        <></>
        // <Layout>
        //     <Seo SeoData={SeoheadData.caseStudyPageSeoData}></Seo>
        //     <CasestudyHero
        //         appbuttonnone={false}
        //         title={subCaseStudy.name}
        //         img={bannerImg}
        //         imgone="../assets/img/casestudy/hero_img/happy_user.svg"
        //         imgtwo="../assets/img/casestudy/hero_img/app-installation.svg"
        //         imgthree="../assets/img/casestudy/hero_img/positive-review.svg"
        //         subheadingone={subCaseStudy.happy_users}
        //         subheadingtwo={subCaseStudy.total_install}
        //         subheadingthree={subCaseStudy.total_reviews}
        //         titleone="Happy User"
        //         titletwo="App Installed"
        //         titlethree="Positive Reviews"
        //     />
        //     <TeamMemberCard
        //         timeduration={subCaseStudy.time_duration}
        //         platform={subCaseStudy.platform}
        //         targetaudience={subCaseStudy.target_audience}
        //         teammembers={subCaseStudy.team_members}
        //     />
        //     <CaseDetail
        //         description={subCaseStudy.description}
        //         casestudy={subCaseStudy.case_study}
        //         briefdescription={subCaseStudy.brief_description}
        //     />
        //     <Techstack techs={subCaseStudy.techs} />
        //     <Accubowaddition
        //         featuretitle={subCaseStudy.feature_title}
        //         features={subCaseStudy.features}
        //         sneakpeek={subCaseStudy.sneak_peek}
        //     />
        //     <CaseVideo videoUrl={subCaseStudy.video_url} />
        //     <Caseslider medias={subCaseStudy.medias} />
        //     <Casedownloadapp
        //         img={footerImg} // footerImg
        //         subheadingone={subCaseStudy.happy_users}
        //         subheadingtwo={subCaseStudy.total_install}
        //         subheadingthree={subCaseStudy.total_reviews}
        //         urldescription={subCaseStudy.url_description}
        //         playstoreurl={subCaseStudy.google_store_url}
        //         applestoreurl={subCaseStudy.apple_store_url}
        //     />
        //     <div className="blog-section case-blog">
        //         <Blog heding="Explore More Case Study" btnnone={false} blogdata={filterCaseData} casestudy={true} bannerImg={bannerImg} />
        //     </div>

        // </Layout>
    )
}