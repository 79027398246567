import { Link } from 'gatsby';
import React from 'react'

function User({ blogData }) {
    return (
        <div className='user'>

            <div className="user-img" >
                {/* <img src={blogData.user_image ? blogData.user_image : `../../assets/img/subblog/message.svg`} alt="message" /> */}
                <img src={blogData.user_image_url ? `${blogData.user_image_url}` : `../../assets/img/subblog/message.svg`} style={{
                    maxWidth: "100%",
                    borderRadius: "50%",
                    height: "100%"
                }} alt={blogData.user_name} />
            </div>

            <div className="user-content">
                <Link to="/blogs">
                    <h2 className='m-0'>{blogData.user_name}</h2>
                </Link>
                <p>{blogData.user_content}</p>
            </div>

        </div>
    )
}

export default User