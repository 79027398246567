import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import allApiData from '../../api';
import { AiFillLinkedin, AiFillHeart } from 'react-icons/ai';
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import { Link, navigate } from "gatsby"
import User from './User';
import Cookies from 'universal-cookie';
import Blog from '../Home/Blog';
import Subblogform from './Subblogform';


function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

function BlogCategory({ blogDetail, footerData, likebutonclickfunc, allData }) {

    const [blogCategoeries, setBlogCategories] = useState([]);
    const pathName = typeof window !== "undefined" ? window.location.href : null;
    const [like, setLike] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    let [searchData, setSearchData] = useState([]);

    let handleSearch = (e) => {
        setSearchValue(e.target.value);
    }
    let searchBlogs = async () => {
        let blogData = await allApiData.searchBlog(searchValue);
        setSearchData(blogData.data.data);
    }
    useEffect(() => {
        debounce(searchBlogs());
    }, [searchValue]);
    useEffect(() => {
        setSearchValue('');
    }, [pathName]);

    const [loading, setLoading] = useState(true);

    let allBlogFilter = allData && allData.filter((_blog) => {
        return _blog.id !== blogDetail.id;
    });
    useEffect(() => {
        // setPageNum(currentPage)
        allApiData.getCategories('blog').then((data) => {
            setBlogCategories(data)
        });
    }, []);
    let truncate = (str) => {
        if (str) {
            return str.length > 160 ? str.substring(0, 160) + "..." : str;
        }
    };
    let filterOnclick = async (e) => {
        navigate('/blogs', {
            state: { blogCategoryTag: e.target.value },
        });
        // if (e.target.value === 'all') {
        // setAllBlogData(blogdata.data);
        // navigate('/blogs');
        // } else {
        // const searchData = await allApiData.filterOnType(e.target.value);
        // setAllBlogData(searchData.data.data);
        // }
    }
    let cookies = new Cookies();
    let handleLike = () => {
        allApiData.addLikeOnBlog(blogDetail.id).then((res) => {
            if (res) {
                cookies.set(`blogLike${blogDetail.id}`, { cookieBlogId: blogDetail.id, like: 'true' }, { path: '/', expires: new Date(Date.now() + 3600000 * 24 * 365 * 20) });
                setLike(true);
                likebutonclickfunc()
            }
        });
    }

    useEffect(() => {
        getCookie(blogDetail.id);
    }, [like, blogDetail.id]);

    let getCookie = (blogIdArg) => {
        if (cookies.get(`blogLike${blogIdArg}`)) {
            const { cookieBlogId } = cookies.get(`blogLike${blogIdArg}`);
            if (cookies.get(`blogLike${blogIdArg}`) && cookieBlogId === blogIdArg) {
                setLike(true);
            }
        }
    }
    useEffect(() => {
        getCookie();
    }, [like]);

    setTimeout(() => {
        setLoading(false);
    }, 1000);


    return (
        <>
            {
                loading ?
                    <>
                        <div className="d-flex justify-content-center py-5" style={{ margin: "3rem 0 12rem" }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </>
                    :
                    <>
                        <div className='sub-hero-main pt-lg-5 mt-lg-4 mt-sm-5 mt-3'>
                            <Container className='container1'>
                                <Row>
                                    <Col xl={7} className=" text-xl-start text-center">
                                        <img src={blogDetail.banner_image ? blogDetail.banner_image : "../assets/img/subblog/hero_img/subbloghero.svg"} alt="subbloghero" className='img-fluid subheroimg' />
                                        <div className="subblog-sidebar-social d-flex  align-items-center subblog-sidebar-social2">
                                            <div className='d-flex  align-items-center add-gap-space'>
                                                <span className='add-gap-space'>{blogDetail.likes}</span>
                                                {
                                                    like
                                                        ?
                                                        <Button className="subblog-baricon subblog-baricon1" disabled={true}>
                                                            <AiFillHeart style={{ fill: '#f44336', fontSize: '30px' }} />
                                                        </Button>
                                                        :
                                                        <Button className="subblog-baricon subblog-baricon1" onClick={handleLike}>
                                                            <img src='../../assets/img/subblog/unlike.svg' style={{ width: '24px' }} />
                                                        </Button>
                                                }
                                            </div>
                                            <div className="subblog-baricon"><Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${pathName}`} target="_blank"><AiFillLinkedin /></Link></div>
                                            <div className="subblog-baricon"><Link to={`https://www.facebook.com/sharer/sharer.php?u=${pathName}&quote=${blogDetail.title}`} target="_blank"><FaFacebookSquare /></Link></div>
                                            <div className="subblog-baricon"><Link to={`https://twitter.com/intent/tweet?text=${blogDetail.tag}&url=${pathName}`} target="_blank"><FaTwitterSquare /></Link></div>
                                            {/* <div className="subblog-baricon">
                                    <a href="#"><FaSkype /></a>
                                    </div> */}
                                        </div>
                                    </Col>

                                    <Col xl={5} className="mt-xl-0 mt-sm-5 mt-4">

                                        <div className="sub-hero-content">
                                            <h6>{blogDetail.category && blogDetail.category.name.toUpperCase()}</h6>
                                            <h1 className='mt-3'>{blogDetail.title}</h1>

                                            <ul className='ps-0 d-flex  mt-3 flex-wrap'>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/blogs">Blogs</Link></li>
                                                <li>{blogDetail.title}</li>
                                            </ul>
                                            <hr />
                                            <p className='subhero-p'>{truncate(blogDetail.content)}</p>
                                            {/* <p className='subhero-p'>{blogDetail.content}</p> */}

                                            <div className="sub-profilebar d-flex align-items-center mt-3">
                                                <div className="sub-prof me-3">
                                                    {/* <img src={blogDetail.user_image ? `https://outsourcingvasundhara.sfo3.cdn.digitaloceanspaces.com/${blogDetail.user_image}` : `../../assets/img/subblog/modelprofile.svg`} alt={blogDetail.user_name} /> */}
                                                    <img src={blogDetail.user_image_url ? `${blogDetail.user_image_url}` : `../../assets/img/subblog/modelprofile.svg`} alt={blogDetail.user_name} />
                                                </div>
                                                <div className="sub-prof-content">
                                                    <h6>{blogDetail.user_name}</h6>
                                                    <p className="sub-prp mt-sm-2 mt-0">{allApiData.dateConverter(blogDetail.date && blogDetail.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Container>
                        </div>

                        <div className='blog-category pb-100'>
                            <div className="subblog-sidebar-social sub-sidebar-none d-flex flex-column align-items-center ">
                                {
                                    like
                                        ?
                                        <Button className="subblog-baricon subblog-baricon1" disabled={true}>
                                            <AiFillHeart style={{ fill: '#f44336', fontSize: '30px' }} />
                                        </Button>
                                        :
                                        <Button className="subblog-baricon subblog-baricon1" onClick={handleLike}>
                                            <img src='../../assets/img/subblog/unlike.svg' style={{ width: '24px' }} />
                                        </Button>
                                }
                                <div className="subblog-text">
                                    <span>{blogDetail.likes}</span>
                                    <p className='my-2'>Share</p>
                                </div>
                                <div className="subblog-baricon"><Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${pathName}`} target="_blank"><AiFillLinkedin /></Link></div>
                                <div className="subblog-baricon"><Link to={`https://www.facebook.com/sharer/sharer.php?u=${pathName}&quote=${blogDetail.title}`} target="_blank"><FaFacebookSquare /></Link></div>
                                <div className="subblog-baricon"><Link to={`https://twitter.com/intent/tweet?text=${blogDetail.tag}&url=${pathName}`} target="_blank"><FaTwitterSquare /></Link></div>

                            </div>
                            <Container className='container1'>

                                <Row className=''>
                                    <Col xl={9} lg={8} md={12} className={`order-lg-0 order-2 sub-blog-container`}>
                                        <div className='blog_contain scrollspy-example' dangerouslySetInnerHTML={{ __html: blogDetail.main_content }}
                                            data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" tabIndex="0"
                                        ></div>
                                        <User blogData={blogDetail} />
                                        <div className="category-box d-lg-none d-block mt-4">
                                            <div className="search-box">
                                                <input type="text" onChange={handleSearch} value={searchValue} placeholder="Search..." />
                                                <div className="serach-icon">
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
                                                </div>
                                            </div>
                                            {
                                                searchValue
                                                    ?
                                                    <ul className='list-unstyled'>
                                                        {
                                                            searchData.length > 0 ?
                                                                searchData && searchData.map((_blog, i) => (
                                                                    <li key={i}>
                                                                        <Link to={`/blogs/${_blog.tag}`} className='list-group-item border-0 px-2 border-bottom'>{_blog.title}</Link>
                                                                    </li>
                                                                )) :
                                                                <li className='list-group-item border-0 px-2 border-bottom'>
                                                                    <p>No More Data</p>
                                                                </li>
                                                        }
                                                    </ul>
                                                    : ''
                                            }
                                            <div className="list">
                                                <h5>Categories</h5>
                                                <ul>
                                                    <li> <img src="../../assets/img/blog/category_img/arrow.svg" alt="arrow" /> <button onClick={(e) => filterOnclick(e)} style={{
                                                        background: "no-repeat",
                                                        outline: "none",
                                                        border: "none"
                                                    }} value="all">All</button> </li>
                                                    {
                                                        blogCategoeries && blogCategoeries.map((title) => {
                                                            return (
                                                                <li>
                                                                    <img src="../../assets/img/blog/category_img/arrow.svg" alt="arrow" />
                                                                    <button onClick={(e) => filterOnclick(e)} style={{
                                                                        background: "no-repeat",
                                                                        outline: "none",
                                                                        border: "none"
                                                                    }} value={title.toLowerCase()}>{title.toUpperCase()}</button>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="category-b-add d-lg-none d-block">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="imager-sub-cate">
                                                    <img src="../../assets/img/subblog/bansi-pipaliya.jpg" alt="message" className='img-fluid' />
                                                </div>
                                                <p>Have a project in mind? Drop a message to Bansi Pipaliya & start the discussion!</p>
                                                <Link to='/contact-us'>
                                                    <button className=" mt-4">Send message</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl={3} lg={4} className="order-lg-2 order-0 mb-lg-0 mb-5" >
                                        <div className="sticky-sub-blog">
                                            {
                                                blogDetail.is_show === 1 ?
                                                    <div className="sub-b-category" id='list-example'>
                                                        <h2>Table of Content</h2>
                                                        <div className='table_of_content' dangerouslySetInnerHTML={{ __html: blogDetail.table_of_content }}></div>
                                                    </div> : ''
                                            }


                                            <div className="category-box d-lg-block d-none">
                                                <div className="search-box">
                                                    <input type="text" onChange={handleSearch} value={searchValue} placeholder="Search..." />
                                                    <div className="serach-icon">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
                                                    </div>
                                                </div>
                                                {
                                                    searchValue
                                                        ?
                                                        <ul className='list-unstyled'>
                                                            {
                                                                searchData.length > 0 ?
                                                                    searchData && searchData.map((_blog, i) => (
                                                                        <li key={i}>
                                                                            <Link to={`/blogs/${_blog.tag}`} className='list-group-item border-0 px-2 border-bottom'>{_blog.title}</Link>
                                                                        </li>
                                                                    )) :
                                                                    <li className='list-group-item border-0 px-2 border-bottom'>
                                                                        <p>No More Data</p>
                                                                    </li>
                                                            }
                                                        </ul>
                                                        : ''
                                                }
                                                <div className="list">
                                                    <h5>Categories</h5>
                                                    <ul>
                                                        <li className={blogDetail.category && blogDetail.category.name.toUpperCase() === 'ALL' ? `active` : ``}> <img src="../../assets/img/blog/category_img/arrow.svg" alt="arrow" /> <button onClick={(e) => filterOnclick(e)} style={{
                                                            background: "no-repeat",
                                                            outline: "none",
                                                            border: "none"
                                                        }} value="all">All</button> </li>
                                                        {
                                                            blogCategoeries && blogCategoeries.map((title, i) => {
                                                                return (
                                                                    <li key={i} className={blogDetail.category && blogDetail.category.name.toUpperCase() === title ? `active` : ``}>
                                                                        <img src="../../assets/img/blog/category_img/arrow.svg" alt="arrow" />
                                                                        <button onClick={(e) => filterOnclick(e)} style={{
                                                                            background: "no-repeat",
                                                                            outline: "none",
                                                                            border: "none"
                                                                        }} value={title.toLowerCase()}>{title.toUpperCase()}</button>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </div>
                                            </div>

                                            <div className="category-b-add d-lg-block d-none">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="imager-sub-cate">
                                                        <img src="../../assets/img/subblog/bansi-pipaliya.jpg" alt="message" className='img-fluid' />
                                                    </div>
                                                    <p>Have a project in mind? Drop a message to Bansi Pipaliya & start the discussion!</p>
                                                    <Link to='/contact-us'>
                                                        <button className=" mt-4">Send message</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>

                        </div>
                        <div className='sub-blog-cat'>
                            <Blog blogdata={allBlogFilter} btnnone={false} limitBlog={3} />
                        </div>

                        <Subblogform blogId={blogDetail.id} />
                    </>
            }
        </>

    )
}

export default BlogCategory