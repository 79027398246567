
import React, { useState, useEffect } from "react";
// import React from 'react';
import Upworkcard from "../components/Mobileapp/Upworkcard"
import Process from "../components/Mobileapp/Process"
import PortfolioCom from "../components/Home/PortfolioCom";
import aallData from "../api.js";
import IndustriesCom from "../components/Home/IndustriesCom";
import Servicecard from '../components/Mobileapp/Servicecard';
import WhyVasundhara from "../components/Home/WhyVasundhara";
import Testimonials from "../components/Home/Testimonials";
import Othersevice from "../components/Mobileapp/Othersevice";
import Blog from "../components/Home/Blog";
import Faq from "../components/Mobileapp/Faq";
import allData from '../allData.js'
import Newgamedev from "../components/Gamedevelopment/newgamedev";
import Hero from "../components/Mobileapp/hero";
import Layout from "../components/Layout";
import allApiData from "../api";
import Seo from "../components/Seo";
import SeoheadData from '../siteScriptData';


const gamecardData = allData.gamecardData;
const industriesData = allData.industriesData;
const gameFaqData = allData.gameFaqData;
const gameProcessData = allData.gameProcessData;
const OtherGameData = allData.otherServiceData('game');
const whygameData = allData.whygamedata;


function Gamedevelopment() {
    const [allData, setallData] = useState({});
    const [portFolio, setPortFolio] = useState([])
    useEffect(() => {
        aallData.getHomeApi().then((response) => {
            setallData(response)
        })
        aallData.allPortfolios('', 'Game Development', 'app_type').then((response) => {
            setPortFolio(response.data);
        })
    }, []);



    useEffect(() => {
        allApiData.aosinit()
    }, []);

    const mobiletabDAta = ['Frontend', 'Backend', 'Testing'];
    // const mobiletabDAta = ['Design','Frontend', 'Backend', 'Testing'];
    return (
        <Layout>
            <Seo SeoData={SeoheadData.mobileGamepageSeoData}></Seo>

            <main>
                <div className="Game-develop">
                    <Hero title=" Mobile Game Development Company" def="We transform your unique ideas into award-winning games. " cta="Get a Quote">
                        <div className="hero-img ms-auto m-0 hide-animation-768">
                            <img src="../assets/img/gamedevelopment/hero_img/mobile-game-development-services.svg" className="wh-auto img-fluid" alt="mobile-game-development-services"
                                width="692" height="600"
                            />
                            <img src="../assets/img/gamedevelopment/hero_img/1.svg" className=" game-img-one img-fluid" alt="mobile-game-development-services-one" />
                            <img src="../assets/img/gamedevelopment/hero_img/2.svg" className=" game-img-two img-fluid" alt="mobile-game-development-services-two" />
                            <img src="../assets/img/gamedevelopment/hero_img/3.svg" className=" game-img-three img-fluid" alt="mobile-game-development-services-three" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/gamedevelopment/hero_img/mobile-game-development-services-mobile.svg" className="wh-auto img-fluid" alt="mobile-game-development-services-mobile"  width="680" height="589" />
                        </div>
                    </Hero>
                </div>
                <Upworkcard />
                <div className='bg-gray game-servicecard app-service-card pb-lg-5'>
                    <Servicecard h1="Mobile Game Development Services"
                        serviceData={gamecardData}
                    />
                </div>
                <div className="game-dept">
                    <Process h1="Our Game Development Process" proccesdata={gameProcessData} />
                </div>

                <div className="bg-blue-light py-lg-5">
                    <PortfolioCom heading="Technologies We Work With" isTabOn={true} tabData={mobiletabDAta} activeTab="Frontend" isImageComp={false} isLimitPortF={10} />
                </div>

                <IndustriesCom h1="Developing Software Solutions for Diverse Verticals" industriesData={industriesData} buttonOn={true} />

                <div className="spacer-div">
                    <Newgamedev title="Building Mobile Games Across Versatile Categories" />
                </div>
                <div className="spacer-head">
                    <PortfolioCom heading="Portfolio of Mobile Game Development Services" isTabOn={false} isImageComp={true} portfolios={portFolio} isLimitPortF={3} />
                </div>
                <WhyVasundhara datas={whygameData} />
                <Testimonials testimonialsdata={allData.testimonials} />
                <Othersevice head="Check Out Other Services We Offer" otherData={OtherGameData} />
                <div className="blog-section nft-blog">
                    <Blog heding="Latest Blogs and Insights from Vasundhara" blogdata={allData.blogs} />
                </div>
                <Faq title="FAQ's  for Game Development Services " faqData={gameFaqData} buttnText='Check out more FAQs here' />
            </main>
        </Layout>

    )
}

export default Gamedevelopment
