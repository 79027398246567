
import React, { useState, useEffect } from "react";
import Upworkcard from "../components/Mobileapp/Upworkcard"
import Process from "../components/Mobileapp/Process"
import aallData from "../api.js";
import Servicecard from '../components/Mobileapp/Servicecard';
import WhyVasundhara from "../components/Home/WhyVasundhara";
import Testimonials from "../components/Home/Testimonials";
import Othersevice from "../components/Mobileapp/Othersevice";
import Blog from "../components/Home/Blog";
import Faq from "../components/Mobileapp/Faq";
import allData from '../allData.js'
import Layout from "../components/Layout";
import Hero from "../components/Mobileapp/hero";
import allApiData from "../api";
import Seo from "../components/Seo";
import Nftmarketplace from "../components/NFT/Nftmarketplace";
import Nftdevelopment from "../components/NFT/Nftdevelopment";
import SeoheadData from "../siteScriptData";
import Benetures from "../components/NFT/Benetures";


const NftcardData = allData.NftcardData;
const nftFaqData = allData.nftFaqData;
const nftProcessData = allData.nftProcessData;
const whynftData = allData.whynftdata;
const OtherNftData = allData.otherServiceData('nft');
const beneturesDataOne = allData.beneturesDataOne;
const beneturesDatatwo = allData.beneturesDataTwo;

function Nft() {
    const [allData, setallData] = useState({});
    useEffect(() => {
        allApiData.aosinit()
        aallData.getHomeApi().then((response) => {
            setallData(response)
        })
    }, [])



    const mobiletabDAta = ['Frontend', 'Backend', 'Testing'];
    return (
        <div></div>
        // <Layout>
        //     <Seo SeoData={SeoheadData.nftPageSeoData}></Seo>
        //     <main>
        //         <div className="nft-develop">
        //             <Hero title="NFT Development Company"
        //                 cta="Get a Quote"
        //                 def="We combine creativity with technology to build unique NFT art."
        //                 btnTitle=" Get a Quote"
        //             >
        //                 <div className="hero-img hide-animation-768 my-5">
        //                     <img src="../assets/img/nft/hero_img/nft-development-services.svg" className="wh-auto z-index img-fluid main-img-only" alt="nft-development-services"
        //                         width="540" height="444"
        //                     />
        //                     <img src="../assets/img/nft/hero_img/1.svg" className="nft-img-one" alt="nft-development-services-one" />
        //                     <img src="../assets/img/nft/hero_img/2.svg" className="nft-img-two" alt="nft-development-services-two" />
        //                     <img src="../assets/img/nft/hero_img/3.svg" className="nft-img-three" alt="nft-development-services-three" />
        //                     <img src="../assets/img/nft/hero_img/4.svg" className="nft-img-four" alt="nft-development-services-four" />
        //                     <img src="../assets/img/nft/hero_img/5.svg" className="nft-img-five" alt="nft-development-services-five" />
        //                     <img src="../assets/img/nft/hero_img/6.svg" className="nft-img-six" alt="nft-development-services-six" />
        //                     <img src="../assets/img/nft/hero_img/7.svg" className="nft-img-seven" alt="nft-development-services-seven" />
        //                     <img src="../assets/img/nft/hero_img/8.svg" className="nft-img-eight" alt="nft-development-services-eight" />
        //                 </div>
        //                 <div className="show-main-img-768">
        //                     <img src="../assets/img/nft/hero_img/nft-development-services-mobile.svg" className="wh-auto img-fluid" alt="nft-development-services-mobile"
        //                         width="680" height="477" />
        //                 </div>
        //             </Hero>
        //         </div>
        //         <Upworkcard />
        //         <div className='bg-gray app-service-card pb-lg-5'>
        //             <Servicecard h1="Non Fungible Token (NFT) Development Services " serviceData={NftcardData} />
        //         </div>
        //         <div className="nft-dept">
        //             <Process h1="Our NFT Development Process" proccesdata={nftProcessData} />
        //         </div>

        //         <div className="pb-bene">
        //             <Benetures h1="Features of NFT Development" beneturesData={beneturesDataOne} />
        //         </div>

        //         <div className="bene-pb-100">
        //             <Benetures h1="Benefits of NFT Development" beneturesData={beneturesDatatwo} />
        //         </div>

        //         {/* <Benetures h1="Benefits of NFT" /> */}

        //         <div className="bg-blue-light ">
        //             <Nftmarketplace heading="Technologies We Work With" />
        //             {/* <PortfolioCom heading="Technologies We Work With"
        //                 isTabOn={true} tabData={mobiletabDAta}
        //                 isImageComp={false}
        //                 portfolios={allData.portfolios}
        //                 isLimitPortF={6} /> */}
        //         </div>
        //         <div className="pt-lg-5">
        //             <Nftdevelopment heading="Portfolio of NFT Development Services" />
        //         </div>

        //         {/* <PortfolioCom heading=" Portfolio of NFT Development Services" portfolios={allData.portfolios}
        //             isLimitPortF={3}
        //             isTabOn={false}
        //             isImageComp={true} /> */}
        //         <WhyVasundhara datas={whynftData} />
        //         <Testimonials testimonialsdata={allData.testimonials} />
        //         <Othersevice head="Check Out Other Services We Offer" otherData={OtherNftData} />
        //         <div className="blog-section nft-blog">
        //             <Blog heding="Latest Blogs and Insights from Vasundhara" blogdata={allData.blogs} />
        //         </div>
        //         <Faq title=" FAQ's for NFT Development Services" faqData={nftFaqData} buttnText='Check out more FAQs here' />
        //     </main>
        // </Layout>
    )
}

export default React.memo(Nft)
