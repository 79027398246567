import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap'
import allData from '../../allData'

export default function Newgamedev({ title = 'Categories' }) {

    return (
        <>
            <section>

                <div className='game-box'>
                    <Container>
                        <div className="heading" data-aos="zoom-in">
                            <h2>{title}</h2>
                        </div>
                        <Row className='mt-sm-5 mt-4 align-items-center justify-content-center' >
                            {
                                allData.gameboxdata.map((e, value) => {
                                    return (
                                        <Col xl={2} lg={3} md={4} sm={6} key={value} className="mt-4"  >
                                            <Card data-aos="zoom-in">
                                                <img src={e.img} alt={e.titlt + e.subtitle} width="120" height="120" className='wh-auto' />
                                                <div className="game-box-title mt-2">
                                                    <h3> {e.titlt} </h3>
                                                    <h3> {e.subtitle} </h3>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
