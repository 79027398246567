import React, { useState, useEffect } from "react";
import Hero from "../components/Mobileapp/hero";
import Upworkcard from "../components/Mobileapp/Upworkcard"
import Process from "../components/Mobileapp/Process"
import PortfolioCom from "../components/Home/PortfolioCom";
import aallData from "../api.js";
import IndustriesCom from "../components/Home/IndustriesCom";
import Servicecard from '../components/Mobileapp/Servicecard';
import WhyVasundhara from "../components/Home/WhyVasundhara";
import Testimonials from "../components/Home/Testimonials";
import Othersevice from "../components/Mobileapp/Othersevice";
import Blog from "../components/Home/Blog";
import Faq from "../components/Mobileapp/Faq";
import allData from '../allData.js'
import Layout from "../components/Layout";
import allApiData from "../api";
import Seo from '../components/Seo';
import SeoheadData from '../siteScriptData';



const webcardData = allData.webcardData;
const industriesData = allData.industriesData;
const webProcessData = allData.webProcessData;
const webFaqData = allData.webFaqData;
const whyWebData = allData.whywebdata;
const OtherwebData = allData.otherServiceData('web');


function Webdevelopment() {
    const [allData, setallData] = useState({});
    const [portFolio, setPortFolio] = useState([])
    useEffect(() => {
        allApiData.aosinit()
        aallData.getHomeApi().then((response) => {
            setallData(response)
        });
        aallData.allPortfolios('', 'Website Development', 'app_type').then((response) => {
            setPortFolio(response.data);
        });
    }, [])

    const mobiletabDAta = ['Frontend', 'Backend', 'Testing'];
    // const mobiletabDAta = ['Design', 'Frontend', 'Backend', 'Testing'];
    return (

        <Layout>
            <Seo SeoData={SeoheadData.webPageSeoData}></Seo>
            <main>
                <div className="web-develop">
                    <Hero title="Web Development Company"
                        cta="Get a Quote"
                        def="We help you take your business to the digital sphere. ">
                        <div className="hero-img hide-animation-768">
                            <img src="../assets/img/webdevelopent/hero_img/web-development-services.svg" className="wh-auto z-index img-fluid " alt="web-development-services"
                             width="743" height="463" />
                            <img src="../assets/img/webdevelopent/hero_img/1.svg" className=" img-fluid one-img" alt="web-development-services-one" />
                            <img src="../assets/img/webdevelopent/hero_img/2.svg" className=" img-fluid two-img" alt="web-development-services-two" />
                            <img src="../assets/img/webdevelopent/hero_img/3.svg" className=" img-fluid three-img" alt="web-development-services-three" />
                            <img src="../assets/img/webdevelopent/hero_img/3.svg" className=" img-fluid four-img" alt="web-development-services-four" />
                            <img src="../assets/img/webdevelopent/hero_img/3.svg" className=" img-fluid five-img" alt="web-development-services-five" />
                            <img src="../assets/img/webdevelopent/hero_img/1.svg" className=" img-fluid six-img" alt="web-development-services-six" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/webdevelopent/hero_img/web-development-services-mobile.svg" className="wh-auto img-fluid" alt="web-development-services-mobile"
                             width="680" height="424" />
                        </div>
                    </Hero>
                </div>
                <Upworkcard />
                <div className='bg-gray game-servicecard app-service-card pb-lg-5'>
                    <Servicecard
                        h1="Web Development Services"
                        serviceData={webcardData}
                    />
                </div>
                <div className="web-dept">
                    <Process h1="Our Web Development Process" proccesdata={webProcessData} />
                </div>
                <div className="bg-blue-light py-lg-5">
                    <PortfolioCom heading="Technologies We Work With" isImageComp={false} isTabOn={true} tabData={mobiletabDAta} activeTab="Frontend" isLimitPortF={11} />
                </div>
                <IndustriesCom h1="Developing Software Solutions for Diverse Verticals" industriesData={industriesData} buttonOn={true} />
                <div className="spacer-head">
                    <PortfolioCom heading="Portfolio of Web Development Services" isTabOn={false} portfolios={portFolio} isImageComp={true} isLimitPortF={3} />
                </div>

                <WhyVasundhara datas={whyWebData} />
                <Testimonials testimonialsdata={allData.testimonials} />
                <Othersevice head="Check Out Other Services We Offer" otherData={OtherwebData} />
                <div className="blog-section nft-blog">
                    <Blog heding="Latest Blogs and Insights from Vasundhara" blogdata={allData.blogs} />
                </div>
                <Faq title="FAQ's  for Web Development Services" faqData={webFaqData} buttnText='Check out more FAQs here' />
            </main>
        </Layout>
    )
}

export default Webdevelopment
